import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout.en";

const IndexPage = () => {
  return (
    <Layout>
      <div class="container">
        <h1>This splash page should not show up in production</h1>
        <p>The CDN will redirect to alert.rcmp.ca/home or alert.grc.ca/acceuil depending on the url</p>
        <div>
          <div className="container">
            <div div className="row text-center">
              <nav className="col-md-9 col-lg-10 ftr-urlt-lnk">
                <ul>
                  <Link to="/home/">English</Link>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Link to="/acceuil/">French</Link>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Link to="/">Splash</Link>
                </ul>
              </nav>
              <div className="col-xs-6 visible-sm visible-xs tofpg">{/* <Link to="#wb-cont">Top of page<span className="glyphicon glyphicon-chevron-up"></span></Link> */}</div>
              <div className="col-xs-6 col-md-3 col-lg-2 text-right">{/* <img alt="Symbol of the Government of Canada" src={footerImg}></img> */}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
